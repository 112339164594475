import './EventsCSS.css'

function Events() {
    return (
        <div className='eventsContainer'>
            Future events will be displayed here
        </div>
    );
}

export default Events;