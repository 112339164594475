import React, {Component} from 'react';
import './MainPageCSS.css'

class MainPage extends Component {
    render() {
        return (
            <div className='mainContainer'>
                We are a Legion of gamers who are united by trust, respect and comradery.
                <br></br>
                We strive for success and aim to be the best.
            </div>
        );
    }
}

export default MainPage;