import './ContactCSS.css'

function Contact() {
    return (
        <div className='contactContainer'>
            You can contact us through discord. Just direct message any of the chiefs.
        </div>
    );
}

export default Contact;